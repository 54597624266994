import React, { createContext, useContext, useState } from 'react'

export enum SpeechSynthesisStatus {
  PLAYING = 'playing',
  STOPPED = 'stopped',
  PAUSED = 'paused',
}

interface SpeechSynthesisProviderProps {
  children: React.ReactNode
}

type SpeechSynthesisContextProps = {
  status: SpeechSynthesisStatus
  pause: (messageId: number) => void
  setStatus: (status: SpeechSynthesisStatus) => void
  message: string
  speak: (message: string, messageId: number) => void
  isPlaying: (messageIdPlaying: number) => boolean
  isPlayingAnotherMessage: (messageIdPlaying: number) => boolean
}

const SpeechSynthesisContext = createContext<SpeechSynthesisContextProps | undefined>(undefined)

export const useSpeech = (): SpeechSynthesisContextProps => {
  const context = useContext(SpeechSynthesisContext)
  if (!context) {
    throw new Error('useSpeech must be used within a SpeechSynthesisProvider')
  }
  return context
}

export const SpeechSynthesisProvider = ({ children }: SpeechSynthesisProviderProps): JSX.Element => {
  const [status, setStatus] = useState<SpeechSynthesisStatus>(SpeechSynthesisStatus.STOPPED)
  const [message, setMessage] = useState<string>('')
  const [messageId, setMessageId] = useState<number | undefined>(undefined)

  const speak = (message: string, messageId: number) => {
    setMessageId(messageId)
    setMessage(message)
    setStatus(SpeechSynthesisStatus.PLAYING)
  }

  const pause = (messageId: number) => {
    setMessageId(messageId)
    setStatus(SpeechSynthesisStatus.PAUSED)
  }

  const isPlayingAnotherMessage = (messageIdPlaying: number): boolean => {
    if (messageId !== messageIdPlaying && status === SpeechSynthesisStatus.PLAYING) {
      return true
    }
    return false
  }

  const isPlaying = (messageIdPlaying: number): boolean => {
    if (messageId === messageIdPlaying && status === SpeechSynthesisStatus.PLAYING) {
      return true
    }
    return false
  }

  return (
    <SpeechSynthesisContext.Provider
      value={{ status, setStatus, message, speak, isPlaying, pause, isPlayingAnotherMessage }}
    >
      {children}
    </SpeechSynthesisContext.Provider>
  )
}
