// External imports
import { Routes, Route } from 'react-router-dom'

// Local imports
// import HomePage from "../pages/Home";
import ChatPage from '../pages/Chat'
import LoginPage from '../pages/login'

// Component definition
function App() {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="chat" element={<ChatPage />} />
    </Routes>
  )
}

// Default export
export default App
